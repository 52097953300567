import React, { useState } from 'react';
import { Box, Typography, Button, Container, TextField, MenuItem } from '@mui/material';
import logo from '../assets/logo.png';
import './css/HomePage.css';
import { fetchFlightData } from '../amadeusService';
import FlightCard from './FlightCard';

const airports = [
  { code: 'DEL', name: 'Indira Gandhi International Airport (Delhi)' },
  
  { code: 'BOM', name: 'Chhatrapati Shivaji Maharaj International Airport (Mumbai)' },
  { code: 'BLR', name: 'Kempegowda International Airport (Bangalore)' },
  { code: 'MAA', name: 'Chennai International Airport (Chennai)' },
  { code: 'HYD', name: 'Rajiv Gandhi International Airport (Hyderabad)' },
  { code: 'CCU', name: 'Netaji Subhas Chandra Bose International Airport (Kolkata)' },
  { code: 'AMD', name: 'Sardar Vallabhbhai Patel International Airport (Ahmedabad)' },
  { code: 'GOI', name: 'Dabolim Airport (Goa)' },
  { code: 'COK', name: 'Cochin International Airport (Kochi)' },
  { code: 'PNQ', name: 'Pune International Airport (Pune)' },
  { code: 'JAI', name: 'Jaipur International Airport (Jaipur)' },
  { code: 'TRV', name: 'Trivandrum International Airport (Trivandrum)' },
  { code: 'JFK', name: 'John F. Kennedy International Airport (New York)' },
  { code: 'LHR', name: 'London Heathrow Airport (London)' },
  { code: 'DXB', name: 'Dubai International Airport (Dubai)' },
  { code: 'SIN', name: 'Singapore Changi Airport (Singapore)' },
  { code: 'HND', name: 'Tokyo Haneda Airport (Tokyo)' },
  { code: 'CDG', name: 'Charles de Gaulle Airport (Paris)' },
  { code: 'LAX', name: 'Los Angeles International Airport (Los Angeles)' },
];

const HomePage = () => {
  const [origin, setOrigin] = useState('DEL');
  const [destination, setDestination] = useState('JFK');
  const [departureDate, setDepartureDate] = useState('2024-12-09');
  const [travelClass, setTravelClass] = useState('Economy');
  const [currency, setCurrency] = useState('INR');  // Default to INR
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dictionaries, setDictionaries] = useState({});

  const searchFlights = async () => {
    setLoading(true);
    try {
      const flightData = await fetchFlightData(origin, destination, departureDate, travelClass.toUpperCase(), currency);
      if (flightData && flightData.data) {
        setFlights(flightData.data);
        setDictionaries(flightData.dictionaries);
      } else {
        setFlights([]);
      }
    } catch (error) {
      console.error('Error fetching flight data:', error);
      setFlights([]);
    }
    setLoading(false);
  };

  // Filter airports for the "To" field by removing the selected "From" airport
  const availableToAirports = airports.filter((airport) => airport.code !== origin);
  // Filter airports for the "From" field by removing the selected "To" airport
  const availableFromAirports = airports.filter((airport) => airport.code !== destination);

  return (
    <Box className="root">
      {/* Header */}
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <Box className="navMenu">
          <Button className="navButton">Log In</Button>
          <Button variant="contained" className="signUpButton">
            Sign Up
          </Button>
        </Box>
      </header>

      {/* Hero Section */}
      <Box className="heroSection">
        <h1>Step into smarter travel with AI</h1>

        {/* Search Bar */}
        <Box className="searchBar">
          {/* From Dropdown */}
          <TextField
            className="searchField animated-input"
            label="From"
            variant="outlined"
            select
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            {availableFromAirports.map((airport) => (
              <MenuItem key={airport.code} value={airport.code}>
                {airport.name} ({airport.code})
              </MenuItem>
            ))}
          </TextField>

          {/* To Dropdown */}
          <TextField
            className="searchField animated-input"
            label="To"
            variant="outlined"
            select
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            {availableToAirports.map((airport) => (
              <MenuItem key={airport.code} value={airport.code}>
                {airport.name} ({airport.code})
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className="searchField animated-input"
            label="Departure Date"
            variant="outlined"
            type="date"
            value={departureDate}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setDepartureDate(e.target.value)}
          />

          <TextField
            className="searchField animated-input"
            label="Travel Class"
            variant="outlined"
            select
            value={travelClass}
            onChange={(e) => setTravelClass(e.target.value)}
          >
            <MenuItem value="Economy">Economy</MenuItem>
            <MenuItem value="Premium Economy">Premium Economy</MenuItem>
            <MenuItem value="Business">Business</MenuItem>
            <MenuItem value="First Class">First Class</MenuItem>
          </TextField>

          <Button className="searchButton animated-button" onClick={searchFlights}>
            <i className="fas fa-search"></i> Search Flights
          </Button>
        </Box>
      </Box>

      {/* Flight Results Section */}
      <Container className="infoSection">
        <h2>Flight Results</h2>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : flights.length > 0 ? (
          <Box className="flightResults">
            {flights.map((flight, index) => (
              <FlightCard key={index} flight={flight} dictionaries={dictionaries} />
            ))}
          </Box>
        ) : (
          <Typography>No flights found.</Typography>
        )}
      </Container>
    </Box>
  );
};

export default HomePage;
