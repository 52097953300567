import axios from 'axios';


const API_KEY = 'SaBbqGxRjIGCoRkKNx3TUzwmDdMN7OAP';  
const API_SECRET = 'YpJ2exv3CZmZfG4W';
const AMADEUS_API_URL = 'test.api.amadeus.com/v1';

// Function to fetch the access token
const getAccessToken = async () => {
    const tokenUrl = `https://test.api.amadeus.com/v1/security/oauth2/token`;
  
    try {
      const response = await axios.post(
        tokenUrl,
        new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: API_KEY,
          client_secret: API_SECRET,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      return response.data.access_token;  // Return the access token
    } catch (error) {
      console.error('Error fetching access token:', error);
      throw new Error('Failed to fetch access token');
    }
  };

export const fetchFlightData = async (origin, destination, departureDate, travelClass, currency) => {
  // First, fetch the access token
  const accessToken = await getAccessToken();
  
  const flightUrl = `https://test.api.amadeus.com/v2/shopping/flight-offers`;
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      originLocationCode: origin,
      destinationLocationCode: destination,
      departureDate: departureDate,
      adults: 1, 
      max: 100, 
      travelClass: travelClass || 'ECONOMY', // Default to economy class
      currencyCode: currency, // Pass the selected currency code
    },
  };

  try {
    const response = await axios.get(flightUrl, options);

    // Return the flight data
    return response.data;
  } catch (error) {
    console.error('Error fetching flight data:', error);
    return null;
  }
};