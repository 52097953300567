import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import './css/FlightCard.css';

const FlightCard = ({ flight, dictionaries }) => {
  const carrierCode = flight.itineraries[0].segments[0].carrierCode;
  const carrierName = dictionaries?.carriers[carrierCode];
  const departure = flight.itineraries[0].segments[0].departure;
  const arrival = flight.itineraries[0].segments[0].arrival;
  const flightNumber = flight.itineraries[0].segments[0].number;
  const duration = flight.itineraries[0].duration;
  
  // Airline logo URL
  const airlineLogoUrl = `https://content.airhex.com/content/logos/airlines_${carrierCode}_350_100_r.png`;

  return (
    <Box className="flight-card">
      {/* Flight Info */}
      <Box className="flight-info">
        {/* Departure Info */}
        <Box className="flight-section">
          <Typography className="airport-code">({departure.iataCode})</Typography>
          <Typography className="airport-name">{dictionaries?.locations[departure.iataCode]?.cityCode}</Typography>
          <Typography className="flight-time">
            Depart: {new Date(departure.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Typography>
        </Box>

        {/* Airline Logo */}
        <Box className="airline-logo-section">
          <img src={airlineLogoUrl} alt={`${carrierName} logo`} className="airline-logo" />
        </Box>

        {/* Arrival Info */}
        <Box className="flight-section">
          <Typography className="airport-code">({arrival.iataCode})</Typography>
          <Typography className="airport-name">{dictionaries?.locations[arrival.iataCode]?.cityCode}</Typography>
          <Typography className="flight-time">
            Arrival: {new Date(arrival.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Typography>
        </Box>
      </Box>

      <Divider />

      {/* Flight Details */}
      <Box className="flight-details">
        <Typography className="details-text">
          Flight No: {flightNumber} | Duration: {duration.replace('PT', '')} | Price: {flight.price.total} {flight.price.currency}
        </Typography>
        <Typography className="view-details">
          View Details
        </Typography>
      </Box>
    </Box>
  );
};

export default FlightCard;
